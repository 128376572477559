import _ from 'lodash'
import API from '@/api'

export default {
    namespaced: true,
    state: {
        inventory: [],
        inventoryMainTypes: [],
        inventoryCategories: [],
        inventoryStatusTypes: [],
        selectedRows: JSON.parse(sessionStorage.getItem('inventoryItems:selectedRows')) || []
    },
    getters: {
        getInventory: (state) => state.inventory,
        getInventoryMainTypes: (state) => state.inventoryMainTypes,
        getInventoryCategories: (state) => state.inventoryCategories,
        getInventoryStatusTypes: (state) => state.inventoryStatusTypes,
        getSelectedRows: (state) => state.selectedRows
    },
    mutations: {
        SET_INVENTORY (state, inventory) {
            state.inventory = inventory
        },
        SET_INVENTORY_MAIN_TYPES (state, mainTypes) {
            state.inventoryMainTypes = mainTypes
        },
        SET_INVENTORY_CATEGORIES (state, categories) {
            state.inventoryCategories = categories
        },
        SET_INVENTORY_STATUS_TYPES (state, statusTypes) {
            state.inventoryStatusTypes = statusTypes
        },
        DELETE_CATEGORY (state, id) {
            state.inventoryCategories = state.inventoryCategories.filter(
                (cat) => cat.id !== id
            )
        },
        SET_SELECTED_ROWS (state, selectedRows) {
            state.selectedRows = selectedRows
            sessionStorage.setItem('inventoryItems:selectedRows', JSON.stringify(selectedRows))
        }
    },
    actions: {
        deleteCategory ({ commit }, id) {
            try {
                API.Categories.deleteCategory('inventoryitems', id).then(() =>
                    commit('DELETE_CATEGORY', id)
                )
            } catch (e) {
                console.error('Error in deleteCategory:', e)
            }
        },
        async setInventory ({ commit }, params) {
            try {
                const inventory = await API.Models.getAll('inventoryitems', params)
                commit('SET_INVENTORY', inventory.data)
            } catch (e) {
                console.error('Error in setInventory:', e)
            }
        },
        async setInventoryMainTypes ({ commit, state }) {
            try {
                if (!_.isEmpty(state.inventoryMainTypes)) return
                const mainTypes = await API.Inventory.getMainTypes()
                commit('SET_INVENTORY_MAIN_TYPES', mainTypes)
            } catch (e) {
                console.error('Error in setInventoryMainTypes:', e)
            }
        },
        async setInventoryCategories ({ commit }) {
            try {
                // if (!_.isEmpty(state.inventoryCategories)) return
                const categories = await API.Tags.getTags({
                    type: 'Inventory'
                })

                // const categories = await API.Inventory.getTags()
                commit('SET_INVENTORY_CATEGORIES', categories?.data || [])
            } catch (e) {
                console.error('Error in setInventoryCategories:', e)
            }
        },
        async setInventoryStatuses ({ commit, state }) {
            try {
                if (!_.isEmpty(state.inventoryStatusTypes)) return
                const statusTypes = await API.Inventory.getStatusTypes()
                commit('SET_INVENTORY_STATUS_TYPES', statusTypes)
            } catch (e) {
                console.error('Error in setInventoryStatuses:', e)
            }
        },
        setSelectedRows ({ commit }, selectedRows) {
            commit('SET_SELECTED_ROWS', selectedRows)
        }
    }
}

import { api } from './apiWrapper'

const baseURL = '/reports'

const getWorkTimeSheetsReport = async (payload) => {
    const url = `${baseURL}/rope_hour_reports`
    return await api.get(url, payload)
}

const getJobNotesReport = async (payload) => {
    const url = `${baseURL}/notes_per_job`
    return await api.get(url, payload)
}

const getWorkAndSafetyReport = async (payload) => {
    const url = `${baseURL}/rope_hours_summary`
    return await api.get(url, payload)
}

const getJobAttendanceReport = async (payload) => {
    const url = `${baseURL}/rope_hours_per_job`
    return await api.get(url, payload)
}

const getOperativeAttendanceReport = async (payload) => {
    const url = `${baseURL}/rope_hours_per_operative`
    return await api.get(url, payload)
}

const getFormsLibraryReport = async (payload) => {
    const url = `${baseURL}/get_forms_data_for_job`
    return await api.get(url, payload)
}

export default {
    getWorkTimeSheetsReport,
    getJobNotesReport,
    getWorkAndSafetyReport,
    getJobAttendanceReport,
    getOperativeAttendanceReport,
    getFormsLibraryReport
}
